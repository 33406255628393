import logo from '../logo.svg';
import './App.css';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {lazy, Suspense} from "react"
import Avatar from "../Elements/Avatar/Avatar";
import HeaderButton from "../Elements/HeaderButton/HeaderButton";
import Cookies from "js-cookie";
import Login from "../Elements/Login";
import Admin from "./Routes/Admin";
import ReadingPane from "../Elements/ReadingPane/ReadingPane";

const Home = lazy(() => import("./Routes/Home"));
const Subscription = lazy(() => import("./Routes/Subscription"));
const Profile = lazy(() => import("./Routes/Profile"));
const Suggestions = lazy(() => import("./Routes/Suggestions"));

const discordURL = "https://cdn.discordapp.com/"

function getAvatar(userid, avatarhash) {
    const fileformat = avatarhash.startsWith("a_") ? "gif" : "png";
    const avatarURL = `avatars/${userid}/${avatarhash}.${fileformat}`
    return discordURL + avatarURL;
}

function App() {

    let user = Cookies.get("auth");

    if (user) {
        try {
            user = JSON.parse(user);

            if (user.avatar) {
                user.avatar = getAvatar(user.id, user.avatar);
            }
        } catch (e) {
            user = undefined;
        }
    }

    const paths = [
        {"path": "/", "name": "Home", "component": Home},
        {"path": "/subscription", "name": "My Subscription", "component": Subscription, "locked": !user},
        {"path": "/profile", "name": "Profile", "component": Profile, "locked": !user},
        {"path": "/suggest", "name": "Suggestions", "component": Suggestions, "locked": !user},
        {"path": "/admin", "name": "Admin", "component": Admin, "hidden": !user || (user.access & 1) === 0},
    ];

    return (
        <BrowserRouter>
            <div id="header">
                <div id="headleft" className="contrast">
                    <Avatar src="/assets/Ox.svg" border={false}/>
                </div>
                <div id="headcenter">
                    {paths.map((path, i) => {
                        console.log(path);
                        if (path.hidden) {
                            return <></>;
                        }
                        return <HeaderButton key={i} text={path['name']} redirect={path.path} locked={path.locked}/>;
                    })}
                </div>
                <div id="headright">
                    <Login user={user}/>
                </div>
            </div>
            <div id="scroller">
                <div id="box">
                    <div id="content">
                        <div className="padding-top-2em background">
                            <ReadingPane>
                                <Switch>
                                    {paths.sort((pathA, pathB) => pathB.path.length - pathA.path.length).map((path, i) => {
                                        return <Route key={i} path={path['path']}
                                                      component={Content(path['component'])}/>;
                                    })}
                                </Switch>
                            </ReadingPane>
                        </div>
                    </div>
                </div>
                <div id="footer">
                    HERE BE FOOTER
                </div>
            </div>
        </BrowserRouter>
    );
}

const renderLoader = () => <p>Loading</p>

function Content(Component) {
    return () => (
        <Suspense fallback={renderLoader()}>
            <Component/>
        </Suspense>
    );
}

export default App;
