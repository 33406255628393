import Cookies from 'js-cookie';
import Avatar from "./Avatar/Avatar";
import HeaderButton from "./HeaderButton/HeaderButton";

export default function Login({user}) {

    if (user && user.username && user.avatar) {
        let {username, avatar} = user;
        return (
            <Avatar src={avatar}/>
        );
    } else {
        return (
            <HeaderButton text="Login" redirect="/disclaimer"/>
        );
    }
}
