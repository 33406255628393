import "./ReadingPane.css"
import Image from "../Image/Image";

export default function ReadingPane({children}) {

    return (
        <div className="reading-pane">
            {children}
        </div>
    );
}
