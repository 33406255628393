
export default function Admin() {

    return (
        <form action="/upload" method="post">
            <input type="file" id="image" name="image" accept="image/*"/>
            <button type="submit">Submit</button>
        </form>
    )
}
