import "./HeaderButton.css";

export default function HeaderButton({text, redirect, locked = false}) {

    const onClick = () => {
        if (!locked) {
            window.location = redirect;
        }
    }

    return (
        <div className={"button buttonContainer" + (locked ? " locked" : "")}>
            <div className="buttonLine"/>
            <div className="buttonAnimation"/>
            <div className="buttonText" onClick={onClick}>
                {text}
            </div>
        </div>
    )
}
